import { combineReducers } from 'redux'
import userReducer from './users'
import searchReducer from './search'
import activityReducer from './activities'
import rolesReducer from './roles'
import categoriesReducer from './categories'
import articlesReducer from './arrticles'
import siteReducer from './site'

export default combineReducers({
    userReducer,
    searchReducer,
    activityReducer,
    rolesReducer,
    categoriesReducer,
    articlesReducer,
    siteReducer
})