import { GET_ALL_USER, REMOVE_USER } from "../constants/index"


const initState = {
    users: []
}

export default (state = initState,action)=>{
    const {type,payload} = action
    switch(type){
        case GET_ALL_USER:{
            return{
                ...state,
                users: payload.users,
                found: payload.found,
                count: payload.count
            }
        }
        case REMOVE_USER: {
            return{
                ...state,
                users: state.users.filter(user => user.id !== payload)
            }
        }
        default:{
            return state
        }
    }
}
