import React, { PureComponent } from 'react'
import './loader.scss'

export default class Loading extends  PureComponent{
    render(){
        return(
            <div>
                {/*<ul className="spinner">*/}
                    {/*<li></li>*/}
                    {/*<li></li>*/}
                    {/*<li></li>*/}
                {/*</ul>*/}
            </div>
        )
    }
}