import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import history from './history.js'
import store from './store'
import { HelmetProvider } from 'react-helmet-async'
import 'react-date-range/dist/theme/default.css'
import 'react-date-range/dist/styles.css'


import App from './App'



ReactDOM.render(
    <div className="site-wrapper">
        <div className="site-container">
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <HelmetProvider>
                        <App />
                    </HelmetProvider>
                </ConnectedRouter>
            </Provider>
        </div>
    </div>
    , document.getElementById('root')
);
