import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
// import ProtectedRoute from './Decorators/protectRoute'
// import {MuiThemeProvider, createMuiTheme} from '@material-ui/core'

import Loadable from 'react-loadable'
import Loading from './loading'
import PreloaderSite from "./components/main/wrapComponent/preloaderSite";
import ReactGA from 'react-ga'
import ProtectedRoute from './Decorators/protectRoute'

// import Admin from './components/admin'

// import MainTemplate from './components/main/index'

// import Login from './components/authorization/login'
// import RegisterComponent from './components/registration/index'


//
// export class Loading extends Component{
//     render(){
//         return(
//             <h1>Loading...</h1>
//         )
//     }
// }


// const ProtectedRoute = Loadable({
//     loader: () => import("./Decorators/protectRoute"),
//     loading: Loading,
// })

const RegisterComponent = Loadable({
    loader: () => import("./components/registration/index"),
    loading: Loading,
})

const Login = Loadable({
    loader: () => import("./components/authorization/login"),
    loading: Loading,
})

const MainTemplate = Loadable({
    loader: () => import("./components/main/index"),
    loading: Loading,
})

const Admin = Loadable({
    loader: () => import("./components/admin/index.js"),
    loading: Loading,
})



class App extends Component {

    constructor(props){
        super(props)
        this.state = {
            preloader: true
        }
    }

    componentDidMount(){

        setTimeout(()=>{
            // console.log(window.location.pathname)
            ReactGA.initialize('UA-57920932-1',{
                // debug: true
            })
            ReactGA.pageview(window.location.pathname + window.location.search);
            this.setState({
                preloader: false
            })
        },3000)
    }

    render() {
        const { preloader } = this.state
        return (
            <div>
                {/*<Link to="/admin">ADMIN</Link>*/}

                    <Switch>
x
                        <Route exact path="/login" render={props => <Login {...props}/>} />
                        <Route exact path="/registration/:token" render={props => <RegisterComponent {...props}/>}/>
                        {/*<Route path="/admin" render={props => <ProtectedRoute {...props} path="/admin" component={Admin}/>} />*/}
                        <Route path="/" render={props => <ProtectedRoute {...props} path="/" component={Admin}/>}/>


                    </Switch>
                    {preloader ? <PreloaderSite/> : null}

            </div>
        );
    }
}


export default App;
