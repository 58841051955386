import React, {Component} from 'react'
import {Route, Redirect} from 'react-router-dom'


export default class ProtectedRoute extends Component {
    render() {
        let user = window.localStorage.getItem('user')
        return (
            user ? <Route {...this.props}></Route> : <Redirect to="/login"/>
        )
    }
}