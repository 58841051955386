import { IS_SEARCH, HANDLE_SEARCH, VALUE_SEARCH } from "../constants/index"

const initState = {
    isSearch: false,
    handleSearch: null,
    valueSearch: null
}

export default (state = initState,action)=>{
    const {type,payload} = action
    switch(type){
        case IS_SEARCH:{
            return{
                ...state,
                isSearch: payload
            }
        }
        case HANDLE_SEARCH: {
            return{
                ...state,
                handleSearch: payload
            }
        }
        case VALUE_SEARCH: {
            return{
                ...state,
                valueSearch: payload
            }
        }
        default:{
            return state
        }
    }
}
