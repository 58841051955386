import { GET_ALL_CATEGORIES,DELETE_CATEGORY } from "../constants/index"

export default (state = {},action) =>{
    const { type,payload } = action
    switch(type){
        case GET_ALL_CATEGORIES: {
            return{
                ...state,
                categories: payload
            }
        }
        case DELETE_CATEGORY: {
            return{
                ...state,
                categories: state.categories.filter(category=> category.id !== payload)
            }
        }
        default: {
            return state
        }
    }
}