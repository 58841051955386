import {
    SITE_CATEGORY,
    SITE_ARTICLES,
    COUNT_SEARCH,
    SITE_ADS
} from "../constants/index"



export default (state = {},action) =>{
    const { type,payload } = action
    switch(type){
        case SITE_ADS: {
            return {
                ...state,
                ads: payload
            }
        }
        case COUNT_SEARCH: {
            return {
                ...state,
                countSearch: payload
            }
        }
        case SITE_CATEGORY: {
            return {
                ...state,
                categories: payload
            }
        }
        case SITE_ARTICLES: {
            return{
                ...state,
                articles: payload
            }
        }
        default: {
            return state
        }
    }
}