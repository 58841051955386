import { GET_ALL_ROLES,CHANGE_ROLE_NAME } from "../constants/index"



export default (state={},action) => {
    const { type,payload } = action
    switch (type){
        case GET_ALL_ROLES: {
            return{
                ...state,
                roles: payload.roles,
                parents: payload.parents
            }
        }
        case CHANGE_ROLE_NAME: {
            return{
                ...state,
                roles: state.roles.map(item => {
                    if(item.roleId === payload.roleId)
                        item.name = payload.name
                    return item
                })
            }
        }
        default: {
            return state
        }
    }
}