import React from 'react'
import './preloader.scss'

export default props => (
    <div className="preloader-wrap">
        <ul className="spinner">
        <li></li>
        <li></li>
        <li></li>
        </ul>
    </div>

)