import { GET_ALL_ACTIVITIES } from "../constants/index"

export default (state={},action)=>{
    const { type,payload } = action
    switch(type){
        case GET_ALL_ACTIVITIES: {
            return {
                ...state,
                activities: payload.activities || [],
                found: payload.found,
                count: payload.count
            }
        }
        default: {
            return state
        }
    }
}