import reducer from '../reducers'
import history from '../history.js'
import { applyMiddleware, compose, createStore } from 'redux'
import loginMiddleware from '../middlewares/login'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
        : compose

const enhancer = composeEnhancers(
    applyMiddleware(thunk, routerMiddleware(history),loginMiddleware)
)

const store = createStore(
    connectRouter(history)(reducer),enhancer
)

window.store = store

export default store