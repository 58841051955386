import { GET_ALL_ARTICLE,DELETE_ARTICLE } from "../constants/index"

export default function (state = {},action){
    const { type,payload } = action
    switch(type){
        case GET_ALL_ARTICLE: {
            return {
                articles: payload
            }
        }
        case DELETE_ARTICLE: {
            return {
                ...state,
                articles: state.articles.filter(item => parseInt(item.id) !== parseInt(payload))
            }
        }
        default: {
            return state
        }
    }
}